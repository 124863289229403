import { Box, Typography, Icon } from "@mui/material";
import dayjs from "dayjs";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

export default function UploadComplete() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          my: "4rem",
          flexShrink: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Typography variant="h5">Upload Complete</Typography>
        <CheckCircleIcon sx={{color: green[500] }} />
      </Box>
    </Box>
  );
}
