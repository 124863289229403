import dayjs from "dayjs";

import {
  currencyValueGetter,
  convertPrice,
  isDate,
  isNumber,
  isPrice,
  isValidOtherColumns,
  isRequired,
  valueSetter,
} from './index';
import {FileHeader} from "../types";

const sortKey = (key: string) =>
  [
    "invoiceDate",
    "invoiceNumber",
    "facilityId",
    "facilityHin",
    "facilityDea",
    "contractId",
    "contractIdDescription",
    "memberName",
    "memberAddressLine1",
    "memberAddressLine2",
    "memberAddressLine3",
    "memberCity",
    "memberState",
    "memberPostalCode",
    "memberCountry",
    "ndc",
    "productDescription",
    "quantity",
    "transactionType",
    "systemAction", // new
    "debitMemoDate", // new
    "chargebackId", // new
    "submittingWholesalerName", // new
    "indirectClassOfTrade", // new
    "indirectClassOfTradeDescription", // new
    "chargebackProcessDate", // new
    "indirectContractPrice", // new
    "indirectExtendedContractSales", // new
    "cbAmount", // new
    "wac",
  ].indexOf(key);


const headers:  { [key: string]: FileHeader } = {
  invoiceDate: {
    key: "invoiceDate",
    header: "Invoice Date",
    required: true,
    objectType: "date",
    sortKey: sortKey("invoiceDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("invoiceDate", [isRequired, isDate]),
    validationFuncs: [isRequired, isDate],
  },
  invoiceNumber: {
    key: "invoiceNumber",
    header: "Invoice Number",
    objectType: "number",
    required: false,
    sortKey: sortKey("invoiceNumber"),
    valueSetter: valueSetter("invoiceNumber", [isNumber]),
    validationFuncs: [isNumber],
  },
  facilityId: {
    key: "facilityId",
    header: "Facility ID",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityId"),
    valueSetter: valueSetter("facilityId", [
      isValidOtherColumns(["facilityDea", "facilityHin"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityDea", "facilityHin"])],
  },
  facilityHin: {
    key: "facilityHin",
    header: "Facility HIN",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityHin"),
    valueSetter: valueSetter("facilityHin", [
      isValidOtherColumns(["facilityDea", "facilityId"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityDea", "facilityId"])],
  },
  facilityDea: {
    key: "facilityDea",
    header: "Facility DEA",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityDea"),
    valueSetter: valueSetter("facilityDea", [
      isValidOtherColumns(["facilityHin", "facilityId"]),
    ]),
    validationFuncs: [isValidOtherColumns(["facilityHin", "facilityId"])],
  },
  contractId: {
    key: "contractId",
    header: "Contract ID",
    objectType: "string",
    required: true,
    sortKey: sortKey("contractId"),
    valueSetter: valueSetter("contractId", []),
    validationFuncs: [],
  },
  contractDescription: {
    key: "contractDescription",
    header: "Contract Description",
    objectType: "string",
    required: false,
    sortKey: sortKey("contractDescription"),
    valueSetter: valueSetter("contractDescription", []),
    validationFuncs: [],
  },
  memberName: {
    key: "memberName",
    header: "Member Name",
    objectType: "string",
    sortKey: sortKey("memberName"),
    required: false,
    valueSetter: valueSetter("memberName", []),
    validationFuncs: [],
  },
  memberAddressLine1: {
    key: "memberAddressLine1",
    header: "Member Address Line 1",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberAddressLine1"),
    valueSetter: valueSetter("memberAddressLine1", []),
    validationFuncs: [],
  },
  memberAddressLine2: {
    key: "memberAddressLine2",
    header: "Member Address Line 2",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberAddressLine2"),
    valueSetter: valueSetter("memberAddressLine2", []),
    validationFuncs: [],
  },
  memberAddressLine3: {
    key: "memberAddressLine3",
    header: "Member Address Line 3",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberAddressLine3"),
    valueSetter: valueSetter("memberAddressLine3", []),
    validationFuncs: [],
  },
  memberCity: {
    key: "memberCity",
    header: "Member City",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberCity"),
    valueSetter: valueSetter("memberCity", []),
    validationFuncs: [],
  },
  memberState: {
    key: "memberState",
    header: "Member State",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberState"),
    valueSetter: valueSetter("memberState", []),
    validationFuncs: [],
  },
  memberPostalCode: {
    key: "memberPostalCode",
    header: "Member Postal Code",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberPostalCode"),
    valueSetter: valueSetter("memberPostalCode", []),
    validationFuncs: [],
  },
  memberCountry: {
    key: "memberCountry",
    header: "Member Country",
    objectType: "string",
    required: false,
    sortKey: sortKey("memberCountry"),
    valueSetter: valueSetter("memberCountry", []),
    validationFuncs: [],
  },
  ndc: {
    key: "ndc",
    header: "NDC",
    objectType: "string",
    required: true,
    sortKey: sortKey("ndc"),
    valueSetter: valueSetter("ndc", [isRequired]),
    validationFuncs: [isRequired],
  },
  productDescription: {
    key: "productDescription",
    header: "Product Description",
    objectType: "string",
    required: true,
    sortKey: sortKey("productDescription"),
    valueSetter: valueSetter("productDescription", [isRequired]),
    validationFuncs: [isRequired],
  },
  quantity: {
    key: "quantity",
    header: "Quantity",
    objectType: "number",
    required: true,
    sortKey: sortKey("quantity"),
    valueSetter: valueSetter("quantity", [isRequired, isNumber]),
    validationFuncs: [isRequired, isNumber],
  },
  transactionType: {
    key: "transactionType",
    header: "Transaction Type",
    objectType: "string",
    required: false,
    sortKey: sortKey("transactionType"),
    valueSetter: valueSetter("transactionType", []),
    validationFuncs: [],
  },
  systemAction: {
    key: "systemAction",
    header: "System Action",
    objectType: "string",
    required: false,
    sortKey: sortKey("systemAction"),
    valueSetter: valueSetter("systemAction", []),
    validationFuncs: [],
  },
  debitMemoDate: {
    key: "debitMemoDate",
    header: "Debit Memo Date",
    required: true,
    objectType: "date",
    sortKey: sortKey("debitMemoDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("debitMemoDate", [isRequired, isDate]),
    validationFuncs: [isRequired, isDate],
  },
  chargebackId: {
    key: "chargebackId",
    header: "Chargeback ID",
    objectType: "string",
    required: true,
    sortKey: sortKey("chargebackId"),
    valueSetter: valueSetter("chargebackId", [isRequired]),
    validationFuncs: [isRequired],
  },
  submittingWholesalerName: {
    key: "submittingWholesalerName",
    header: "Submitting Wholesaler Name",
    objectType: "string",
    required: true,
    sortKey: sortKey("submittingWholesalerName"),
    valueSetter: valueSetter("submittingWholesalerName", [isRequired]),
    validationFuncs: [isRequired],
  },
  indirectClassOfTrade: {
    key: "indirectClassOfTrade",
    header: "Indirect Class of Trade",
    objectType: "string",
    required: true,
    sortKey: sortKey("indirectClassOfTrade"),
    valueSetter: valueSetter("indirectClassOfTrade", [isRequired]),
    validationFuncs: [isRequired],
  },
  indirectClassOfTradeDescription: {
    key: "indirectClassOfTradeDescription",
    header: "Indirect Class of Trade Description",
    objectType: "string",
    required: true,
    sortKey: sortKey("indirectClassOfTradeDescription"),
    valueSetter: valueSetter("indirectClassOfTradeDescription", [isRequired]),
    validationFuncs: [isRequired],
  },
  chargebackProcessDate: {
    key: "chargebackProcessDate",
    header: "Chargebackback Process Date",
    required: true,
    objectType: "date",
    sortKey: sortKey("chargebackProcessDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("chargebackProcessDate", [isRequired, isDate]),
    validationFuncs: [isRequired, isDate],
  },
  indirectContractPrice: {
    key: "indirectContractPrice",
    header: "Indirect Contract Price",
    objectType: "string",
    required: true,
    sortKey: sortKey("indirectContractPrice"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "indirectContractPrice",
      [isRequired, isPrice],
      convertPrice,
    ),
    validationFuncs: [isRequired, isPrice],
  },
  cbAmount: {
    key: "cbAmount",
    header: "CB Price",
    objectType: "string",
    required: true,
    sortKey: sortKey("cbAmount"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "cbAmount",
      [isRequired, isPrice],
      convertPrice,
    ),
    validationFuncs: [isRequired, isPrice],
  },
  wac: {
    key: "wac",
    header: "WAC",
    objectType: "string",
    required: true,
    sortKey: sortKey("wac"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "wac",
      [isRequired, isPrice],
      convertPrice,
    ),
    validationFuncs: [isRequired, isPrice],
  },
}

export default headers;