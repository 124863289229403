import dayjs from "dayjs";

import {
  currencyValueGetter,
  convertPrice,
  isDate,
  isNumber,
  isPrice,
  isRequired,
  valueSetter,
} from './index';
import {FileHeader} from "../types";

const sortKey = (key: string) =>
  [
    "creditDate",
    "creditNumber",
    "customerName",
    "customerAddressLine1",
    "customerAddressLine2",
    "customerAddressLine3",
    "customerAddressCity",
    "customerAddressState",
    "customerAddressPostalCode",
    "customerAddressCountry",
    "classOfTrade",
    "classOfTradeStatus",
    "facilityId",
    "contractId",
    "ndc",
    "productDescription",
    "quantity",
    "creditAmount",
    "creditType",
    "creditTypeClassification",
    "systemAction"
  ].indexOf(key);


const headers: { [key: string]: FileHeader } = {
  invoiceDate: {
    key: "creditDate",
    header: "Credit Date",
    required: false,
    objectType: "date",
    sortKey: sortKey("creditDate"),
    valueGetter: (value: any, row: any) => {
      return dayjs(value).isValid() ? dayjs(value).toDate() : undefined;
    },
    valueSetter: valueSetter("creditDate", [isDate]),
    validationFuncs: [isDate],
  },
  creditNumber: {
    key: "creditNumber",
    header: "Credit Number",
    objectType: "number",
    required: false,
    sortKey: sortKey("creditNumber"),
    valueSetter: valueSetter("creditNumber", [isNumber]),
    validationFuncs: [isNumber],
  },
  customerName: {
    key: "customerName",
    header: "Customer Name",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerName"),
    valueSetter: valueSetter("customerName", []),
    validationFuncs: [],
  },
  customerAddressLine1: {
    key: "customerAddressLine1",
    header: "Customer Address Line 1",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressLine1"),
    valueSetter: valueSetter("customerAddressLine1", []),
    validationFuncs: [],
  },
  customerAddressLine2: {
    key: "customerAddressLine2",
    header: "Customer Address Line 2",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressLine2"),
    valueSetter: valueSetter("customerAddressLine2", []),
    validationFuncs: [],
  },
  customerAddressLine3: {
    key: "customerAddressLine3",
    header: "Customer Address Line 3",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressLine3"),
    valueSetter: valueSetter("customerAddressLine3", []),
    validationFuncs: [],
  },
  customerAddressCity: {
    key: "customerAddressCity",
    header: "Customer Address City",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressCity"),
    valueSetter: valueSetter("customerAddressCity", []),
    validationFuncs: [],
  },
  customerAddressState: {
    key: "customerAddressState",
    header: "Customer Address State",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressState"),
    valueSetter: valueSetter("customerAddressState", []),
    validationFuncs: [],
  },
  customerAddressPostalCode: {
    key: "customerAddressPostalCode",
    header: "Customer Address Postal Code",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressPostalCode"),
    valueSetter: valueSetter("customerAddressPostalCode", []),
    validationFuncs: [],
  },
  customerAddressCountry: {
    key: "customerAddressCountry",
    header: "Customer Address Country",
    objectType: "string",
    required: false,
    sortKey: sortKey("customerAddressCountry"),
    valueSetter: valueSetter("customerAddressCountry", []),
    validationFuncs: [],
  },
  classOfTrade: {
    key: "classOfTrade",
    header: "Class of Trade",
    objectType: "string",
    required: false,
    sortKey: sortKey("classOfTrade"),
    valueSetter: valueSetter("classOfTrade", []),
    validationFuncs: [],
  },
  classOfTradeStatus: {
    key: "classOfTradeStatus",
    header: "Class of Trade Status",
    objectType: "string",
    required: false,
    sortKey: sortKey("classOfTradeStatus"),
    valueSetter: valueSetter("classOfTradeStatus", []),
    validationFuncs: [],
  },
  facilityId: {
    key: "facilityId",
    header: "Facility ID",
    objectType: "string",
    required: false,
    sortKey: sortKey("facilityId"),
    valueSetter: valueSetter("facilityId", []),
    validationFuncs: []
  },
  contractId: {
    key: "contractId",
    header: "Contract ID",
    objectType: "string",
    required: false,
    sortKey: sortKey("contractId"),
    valueSetter: valueSetter("contractId", []),
    validationFuncs: [],
  },
  ndc: {
    key: "ndc",
    header: "NDC",
    objectType: "string",
    required: true,
    sortKey: sortKey("ndc"),
    valueSetter: valueSetter("ndc", [isRequired]),
    validationFuncs: [isRequired],
  },
  productDescription: {
    key: "productDescription",
    header: "Product Description",
    objectType: "string",
    required: false,
    sortKey: sortKey("productDescription"),
    valueSetter: valueSetter("productDescription", []),
    validationFuncs: [],
  },
  quantity: {
    key: "quantity",
    header: "Quantity",
    objectType: "number",
    required: false,
    sortKey: sortKey("quantity"),
    valueSetter: valueSetter("quantity", [isNumber]),
    validationFuncs: [isNumber],
  },
  creditAmount: {
    key: "creditAmount",
    header: "Credit Amount",
    objectType: "string",
    required: true,
    sortKey: sortKey("creditAmount"),
    price: true,
    valueGetter: currencyValueGetter,
    valueSetter: valueSetter(
      "creditAmount",
      [isPrice, isRequired],
      convertPrice,
    ),
    validationFuncs: [isPrice, isRequired],
  },
  creditTypeClassification: {
    key: "creditTypeClassification",
    header: "Credit Type Classification",
    objectType: "string",
    required: false,
    sortKey: sortKey("creditTypeClassification"),
    valueSetter: valueSetter("creditTypeClassification", []),
    validationFuncs: [],
  },
  systemAction: {
    key: "systemAction",
    header: "System Action",
    objectType: "string",
    required: false,
    sortKey: sortKey("systemAction"),
    valueSetter: valueSetter("systemAction", []),
    validationFuncs: [],
  },
}

export default headers;