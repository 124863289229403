import React from "react";
import { useDropzone } from "react-dropzone";
import { Modal, Typography, Box, Button, CircularProgress } from "@mui/material";
import { useFileUploaderContext } from "../context/fileUploader.context";
import toast from "react-hot-toast";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function UploadSpreadsheet() {
  const { setWorksheets, worksheets, setWorksheetData, setFileUploaderState } = useFileUploaderContext();

  const [processingFile, setProcessingFile] = React.useState<boolean>(false);
  const [sheetNames, setSheetNames] = React.useState<string[]>([]);
  const [showSelectSheetModal, setShowSelectSheetModal] = React.useState<boolean>(false);

  const onDrop = (acceptedFiles: any) => {
    setProcessingFile(true)
    worker.postMessage({ file: acceptedFiles[0] });
  };

  const worker = new Worker(
    URL.createObjectURL(
      new Blob([
        `\
/* load standalone script from CDN */
importScripts("https://cdn.sheetjs.com/xlsx-0.20.2/package/dist/xlsx.full.min.js");


function sheet_to_json_cb(ws, cb, opts, batch = 1000000000) {
  XLSX.stream.set_readable(() => ({
    __done: false,
    // this function will be assigned by the SheetJS stream methods
    _read: function() { this.__done = true; },
    // this function is called by the stream methods
    push: function(d) { if(!this.__done) cb(d); if(d == null) this.__done = true; },
    resume: function pump() { for(var i = 0; i < batch && !this.__done; ++i) this._read(); if(!this.__done) setTimeout(pump.bind(this), 0); }
  }));
  return XLSX.stream.to_json(ws, { header: "A" });
}

/* this callback will run once the main context sends a message */
self.addEventListener('message', (e) => {
  try {
    postMessage({ state: "reading file" })
    /* Read file data */
    const ab = new FileReaderSync().readAsArrayBuffer(e.data.file);

    /* Parse file */
    let len = ab.byteLength;
    if(len < 1024) len += " bytes"; else { len /= 1024;
      if(len < 1024) len = Math.round(len) + " KB"; else { len /= 1024; len = Math.round(len) + " MB"; }
    }
    postMessage({state: "parsing", len: ab.byteLength, size: len });

    /* Parse file */
    const wb = XLSX.read(ab, {dense: true, cellDates: true });

    postMessage({ state: 'parsed', ab: ab, sheetNames: wb.SheetNames });

    const sheetNames = wb.SheetNames;
    for(let i = 0; i < sheetNames.length ; i++){
      const ws = wb.Sheets[sheetNames[i]]
      const numOfRows = ws["!ref"];
      const strm = sheet_to_json_cb(ws, (json) => {
        if(json != null) postMessage({ state: 'streaming', sheetIndex: i, json, numOfRows });
        else postMessage({state: "finished_ws", sheetName: sheetNames[i], sheetIndex: i, error: undefined });
      });
      strm.resume();
    }

    /* Reply with result */
    postMessage({ state: 'complete', sheetNames, error: undefined });
  } catch(e) {
    /* Pass the error message back */
    // postMessage({ws: String(e.message || e).bold() });
    postMessage({sheets: [], sheetNames: [], error: String(e.message || e).bold() });
  }
}, false);
  `,
      ]),
    ),
  );

  worker.onmessage = function (e) {
    if(e.data.error) {
      console.error(e.data.error); /* show an error message */
      setProcessingFile(false)
    } else if(e.data.state === 'streaming') {
      console.log('streaming', e.data.numOfRows)
      setWorksheets && setWorksheets((prevArr: any[]) => {
        if(prevArr[e.data.sheetIndex] === undefined){
          prevArr[e.data.sheetIndex] = []
        }
        prevArr[e.data.sheetIndex].push(e.data.json)
        console.log('prevArr[e.data.sheetIndex].length', prevArr[e.data.sheetIndex].length, 'for_index', e.data.sheetIndex, 'json', e.data.json)
        return prevArr;
      })
    } else if (e.data.state === 'finished_ws'){
      console.log("completed one worksheet", e.data.sheetName, e.data.sheetIndex)
    } else if(e.data.state === 'parsing') {
      // postMessage({state: "parsing", len: ab.byteLength;, size: len });
      console.log(e.data.state); /* current state */
      toast.success(`Parsing file size of ${e.data.size}.`);
      let len = e.data.len;
      if(len > 1024) {
        len /= 1024;
        if(len > 1024) {
          len /= 1024;
          if(len > 5){
            toast.success(`The file is abnorminally large and will take a while to process.`);
          }
        }
      }
    } else if(e.data.state === 'parsed') {
      toast.success("File has been parsed. Formating the data.");
    } else if(e.data.state === 'complete') {
      toast.success("File upload is complete.");

      setProcessingFile(false)
      setSheetNames(e.data.sheetNames);

      worker.terminate();

      if(e.data.sheetNames.length > 1){
        setShowSelectSheetModal(true)
      } else {
        setWorksheetData && setWorksheetData(worksheets && worksheets[0]);
        setFileUploaderState &&
          setFileUploaderState("uploaded_valid_spreadsheet");
      }
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
          ".xlsb"
        ],
      },
      maxFiles: 1,
      onDrop: onDrop,
    });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <div className="flex flex-col">
      {processingFile ?
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}><CircularProgress size="3rem" /></Box>
        : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only *.xlsx and *.csv files will be accepted)</em>
        </div>
      )}
      <Modal open={showSelectSheetModal} onClose={() => setShowSelectSheetModal(false)}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ mb: "2rem"}}>
            <Typography variant={"h6"}>Select Excel sheet to use</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "1rem"}}>
              {sheetNames.map((sheetName: string, index) => (
                <Box sx={{ flex: 1}} key={sheetName}>
                  <Button fullWidth variant={"contained"} onClick={() => {
                    setWorksheetData && setWorksheetData(worksheets && worksheets[index]);
                    setFileUploaderState &&
                      setFileUploaderState("uploaded_valid_spreadsheet");
                    setShowSelectSheetModal(false)
                  }}>{sheetName}</Button>
                </Box>
              ))}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
